import { FormEvent, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { formToJson } from "../data/api";
import { changePassword } from "../data/auth";
import Page from "../Page";
import {
  BaseApiStatus,
  LoadingStatus,
  setApiStatus,
  TApiStatusResponse,
} from "../utils/apiStatus";

const Settings = () => {
  const [passwordStatus, setPasswordStatus] =
    useState<TApiStatusResponse>(BaseApiStatus);

  const handleChangePassword = (e: FormEvent) => {
    e.preventDefault();
    setPasswordStatus(LoadingStatus);
    const data = formToJson(e);
    if (data.new_password !== data.confirm_new_password) {
      setPasswordStatus({
        showAlert: true,
        loading: false,
        error: {
          status: 400,
          response: null,
          message: "Passwords do not match",
        },
        success: undefined,
      });
      return;
    } else if (data.old_password === data.new_password) {
      setPasswordStatus({
        showAlert: true,
        loading: false,
        error: {
          status: 400,
          response: null,
          message: "New password cannot be the same as old password",
        },
        success: undefined,
      });
      return;
    }
    setApiStatus(changePassword, data, setPasswordStatus);
  };

  return (
    <Page title="Settings">
      <h4>Change Password</h4>
      <Form onSubmit={handleChangePassword}>
        <Form.Group className="mb-2">
          <Form.Label>Old Password</Form.Label>
          <Form.Control type="password" name="old_password" />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" name="new_password" />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Confirm New Password</Form.Label>
          <Form.Control type="password" name="confirm_new_password" />
        </Form.Group>
        <Form.Group className="mb-2">
          <Button
            type="submit"
            variant="primary"
            disabled={passwordStatus.loading}
          >
            <div className="flex flex-align-center">
              {passwordStatus.loading && (
                <Spinner animation="border" size="sm" />
              )}
              Change Password
            </div>
          </Button>
        </Form.Group>
      </Form>
      {passwordStatus.showAlert &&
        (passwordStatus.success ? (
          <div className="alert alert-success" role="alert">
            Password changed successfully
          </div>
        ) : (
          <div className="alert alert-danger" role="alert">
            Password change failed. <br />
            {passwordStatus.error?.message && (
              <>
                Reason: <strong>{passwordStatus.error?.message}</strong>
              </>
            )}
          </div>
        ))}
    </Page>
  );
};

export default Settings;
