//@ts-nocheck
import { useQuery, useQueryClient } from "react-query";
import {
  QUERY_KEY as CollegeQueryKey,
  listColleges,
  resetCollege,
  resetCollegePassword,
} from "../data/college";
import { useState } from "react";
import Page from "../Page";
import Spinner from "react-bootstrap/esm/Spinner";
import Table from "react-bootstrap/esm/Table";
import Pagination from "../components/Pagination";
import Form from "react-bootstrap/esm/Form";
import debounce from "lodash/debounce";
import Button from "react-bootstrap/esm/Button";
import Alert from "react-bootstrap/esm/Alert";
import keyBy from "lodash/keyBy";
import ResetConfirmation from "./ResetConfirmation";
import ResetCollegePassword from "./ResetPassword";
import { Link, useNavigate } from "react-router-dom";

const CollegeIndex = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const getParams = () => {
    const params: any = {};
    if (searchQuery) {
      params.search = searchQuery;
    }
    params.page_size = 20;
    params.page = pageNumber;
    return params;
  };
  const { data: collegeResponse, ...collegeResponseMeta } = useQuery(
    [CollegeQueryKey, getParams()],
    listColleges,
    {
      staleTime: Infinity,
    }
  );
  const isLoading = collegeResponseMeta.isLoading;
  const collegeMap = keyBy(collegeResponse?.data?.results || [], "id");

  const debouncedSearch = debounce((event) => {
    const query = event.target.value;
    if (query.length >= 3) setSearchQuery(query);
    else setSearchQuery(undefined);
    setPageNumber(1);
  }, 500);

  const [collegeToReset, setCollegeToReset] = useState(undefined);
  const [showResetConfirmModal, setShowResetConfirmModal] = useState(false);

  const [collegeToResetPassword, setCollegeToResetPassword] =
    useState(undefined);
  const [showResetPasswordConfirmModal, setShowResetPasswordConfirmModal] =
    useState(false);

  const [showResetToast, setShowResetToast] = useState({
    show: false,
    message: undefined | Node,
    error: undefined,
  });

  const queryClient = useQueryClient();

  const handleClickOnReset = (collegeId) => () => {
    setCollegeToReset(collegeId);
    setShowResetConfirmModal(true);
  };

  const handleResetCanceled = () => {
    setShowResetConfirmModal(false);
    setCollegeToReset(undefined);
  };
  const handleClickOnResetPassword = (collegeId) => () => {
    setCollegeToResetPassword(collegeId);
    setShowResetPasswordConfirmModal(true);
  };
  const handleClickOnResetPasswordCanceled = () => {
    setCollegeToResetPassword(undefined);
    setShowResetPasswordConfirmModal(false);
  };

  const handleResetCollege = () => {
    const resetedCollegeName = collegeMap[collegeToReset]?.name;

    resetCollege(collegeToReset)
      .then((response) => {
        if (response.status === 204) {
          setShowResetToast({
            show: true,
            message: (
              <p>
                <strong>{resetedCollegeName}</strong> has been reset. <br />
                The college will be able to add, edit and delete students and
                teams.
              </p>
            ),
            error: undefined,
          });
          queryClient.invalidateQueries([CollegeQueryKey, getParams()]);
        } else {
          setShowResetToast({
            show: true,
            resetedCollegeName: undefined,
            error: response?.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowResetToast({
          show: true,
          resetedCollegeName: undefined,
          error: "Some error occurred.",
        });
      })
      .finally(() => {
        setShowResetConfirmModal(false);
      });
  };

  const handleResetCollegePassword = () => {
    const resetedCollegeName = collegeMap[collegeToResetPassword]?.name;

    resetCollegePassword(collegeToResetPassword)
      .then((response) => {
        if (response.status === 200) {
          setShowResetToast({
            show: true,
            message: (
              <div>
                <p>
                  Password reset for <strong>{resetedCollegeName}</strong>.{" "}
                  <br />
                  New password: <strong>{response.data.password}</strong> <br />
                </p>
                <p>
                  <strong>Note: </strong>
                  The users of this college will have to use the new password.{" "}
                  <br />
                </p>
              </div>
            ),
            error: undefined,
          });
          queryClient.invalidateQueries([CollegeQueryKey, getParams()]);
        } else {
          setShowResetToast({
            show: true,
            resetedCollegeName: undefined,
            error: response?.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowResetToast({
          show: true,
          resetedCollegeName: undefined,
          error: "Some error occurred.",
        });
      })
      .finally(() => {
        setShowResetPasswordConfirmModal(false);
      });
  };


  const handlePrintClick = (collegeId) => () =>
    navigate(`/app/printout/?collegeId=${collegeId}`);




  return (
    <Page title="Colleges">
      <ResetConfirmation
        showResetConfirmModal={showResetConfirmModal}
        handleResetCanceled={handleResetCanceled}
        handleResetCollege={handleResetCollege}
        collegeToReset={collegeMap[collegeToReset]}
      />
      <ResetCollegePassword
        showResetConfirmModal={showResetPasswordConfirmModal}
        handleResetCanceled={handleClickOnResetPasswordCanceled}
        handleResetPassword={handleResetCollegePassword}
        collegeToReset={collegeMap[collegeToResetPassword]}
      />

      {showResetToast.show && showResetToast.message ? (
        <Alert
          variant="success"
          onClose={() => setShowResetToast({ show: false })}
        >
          {showResetToast.message}
        </Alert>
      ) : null}
      {showResetToast.show && showResetToast.error ? (
        <Alert
          variant="danger"
          onClose={() => setShowResetToast({ show: false })}
        >
          <p>
            {showResetToast.error?.non_field_errors || "Some error occurred. "}
          </p>
        </Alert>
      ) : null}
      <div>
        <Link to="./add" className="btn btn-primary mb-2">
          Add College
        </Link>
        <div className="mb-4 d-flex align-items-center">
          <div className="me-2 flex-grow-1">
            <Form.Control
              type="text"
              placeholder="Search college by name or user id"
              onChange={debouncedSearch}
            />
          </div>
        </div>
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Table hover striped>
              <thead>
                <th>#</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Teams</th>
                <th>Has Confirmed?</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {(collegeResponse?.data?.results || []).map((college, i) => (
                  <tr>
                    <td>{(pageNumber - 1) * 20 + i + 1}</td>
                    <td>{college.username}</td>
                    <td>{college.name}</td>
                    <td>{college.max_teams_per_college_per_event}</td>
                    <td>{college.has_confirmed ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        size="sm"
                        variant="warning"
                        onClick={handleClickOnReset(college.id)}
                      >
                        Reset Confirmation
                      </Button>
                      <Button
                        size="sm"
                        variant="danger"
                        className="ms-2"
                        onClick={handleClickOnResetPassword(college.id)}
                      >
                        Reset Password
                      </Button>
                      <Button
                        size="sm"
                        variant="info"
                        className="ms-2"
                        onClick={handlePrintClick(college.id)}
                      >
                        Printout
                      </Button>
                      <Button
                        size="sm"
                        variant="info"
                        className="ms-2"
                        href={`/app/colleges/edit/${college.id}`}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              currentPage={pageNumber}
              itemCount={collegeResponse?.data?.count || 0}
              setPage={setPageNumber}
              itemsPerPage={20}
            />
          </>
        )}
      </div>
    </Page>
  );
};

export default CollegeIndex;
