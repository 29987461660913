//@ts-nocheck
import Alert from "react-bootstrap/esm/Alert"


const HasConfirmedBlocker = props => {
    if (!props.confirmationStatus.isLoading && props.confirmationStatus.hasConfirmed) {
        return (<Alert variant="warning">
            All submissions for this college has been confirmed. <br /> 
            Any edits to the current submission is not allowed now. 
        </Alert>)
    } else {
        return null
    }
}

export default HasConfirmedBlocker
