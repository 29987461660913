//@ts-nocheck
import Page from "../Page"
import { useQuery, useQueryClient } from "react-query"
import { useState } from "react"
import { listSchedule, QUERY_KEY as ScheduleQueryKey, addSchedule } from "../data/schedule"
import Form from "react-bootstrap/esm/Form"
import debounce from "lodash/debounce"
import Table from "react-bootstrap/esm/Table"
import moment from "moment"
import EventSelect from "../components/EventSelect"
import Spinner from "react-bootstrap/esm/Spinner"
import Button from "react-bootstrap/esm/Button"
import ResponseAlert from "../components/ResponseAlert"
import { formToJson } from "../data/api"
import { setApiStatus } from "../utils/apiStatus"
import { useQueryParams } from "../utils/location"

const ScheduleIndex = () => {
    const queryParams = useQueryParams()
    const eventName = queryParams.get("event")
    
    const [searchQuery, setSearchQuery] = useState(eventName || "")
    const handleSearch = debounce((q) => setSearchQuery(q.target.value), 200)

    const [addScheduleStatus, setAddScheduleStatus] = useState({ loading: false, showAlert: false })
    const [event, setEvent] = useState(null)
    const queryClient = useQueryClient()
    
    const handleEventChange = e => {
        setEvent(e[0]?.id)
    }
    const addScheduleCallBack = (newStatus) => {
        setAddScheduleStatus(newStatus)
        if (!!newStatus.success) {
            queryClient.invalidateQueries(ScheduleQueryKey)
        }
    }
    const handleAddSchedule = (e) => {
        e.preventDefault()
        const data = formToJson(e)
        data.event = event
        setApiStatus(addSchedule, data, addScheduleCallBack)
    }


    const { data: scheduleResponse, ...scheduleMeta } = useQuery(ScheduleQueryKey, listSchedule)
    if (scheduleMeta.isLoading || scheduleMeta.isFetching) {
        return <Spinner animation="border" />
    }

    const schedules = searchQuery
        ? (scheduleResponse?.data?.results || []).filter((s: { event: { name: string } }) => s.event.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
        : (scheduleResponse?.data?.results || [])


    return <Page title="Schedule">
        <Form.Control placeholder="Search for event" onChange={handleSearch} defaultValue={eventName || ""} />
        <div className="row mt-4">
            <div className="col-md-9">
                {scheduleMeta.isLoading ? <Spinner animation="border" /> :
                    <Table hover striped bordered className="mt-2">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Event</th>
                                <th>Venue</th>
                                <th>Schedule</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedules.map((s: any, i: number) => (<tr>
                                <td>{i + 1}</td>
                                <td>{s.event.category.name}</td>
                                <td>{s.event.name}</td>
                                <td>{s.venue}</td>
                                <td>{moment(s.from_time).format("Do MMM, h:mm A")} - {moment(s.to_time).format("h:mm A")}</td>
                            </tr>))}
                            {schedules.length === 0 && (<tr>
                                <td colSpan={5}>No results.</td>
                            </tr>)}
                        </tbody>
                    </Table>
                }
            </div>
            <div className="col-md-3 mt-">
                <h4>Add new schedule</h4>
                <p className="text-muted">If a schedule for the event was already added, it will be overwritten.</p>
                <Form onSubmit={handleAddSchedule}>
                    <Form.Group>
                        <EventSelect onChange={handleEventChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Venue</Form.Label>
                        <Form.Control name="venue" placeholder="Eg. Auditorium" type="text" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>From</Form.Label>
                        <Form.Control name="from_time" type="datetime-local" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>To</Form.Label>
                        <Form.Control name="to_time" type="datetime-local" />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Button type="submit" style={{ width: "100%" }}>Save</Button>
                    </Form.Group>
                </Form>
                <div className="mt-2">
                <ResponseAlert
                    status={addScheduleStatus}
                    successHeader=""
                    successBody="Added successfully."
                    errorHeader={addScheduleStatus?.error?.status === 400 ? "Error" : "Some error occurred."}
                    errorBody={addScheduleStatus?.error?.status === 400 ? addScheduleStatus?.error?.response?.error : ""}
                />
                </div>
            </div>
        </div>
    </Page>
}

export default ScheduleIndex
