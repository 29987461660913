import { authAxios } from "./api"

export const QUERY_KEY = "managers"
export const BASE_URL = "app/users/"


export const listManagers = ({ queryKey }: { queryKey: any }) => {
    const params = queryKey[1]
    const extendParams = {
        ...params,
        type:["Manager", "Staff"]
    }
    const queryParams = Object.keys(extendParams).map(p => `${p}=${extendParams[p]}`).join("&")
    const url = BASE_URL + (queryParams ? `?${queryParams}` : "")
    return authAxios.get(url)
}

export const addManager = (data: any) => authAxios.post(BASE_URL, data)
export const removeManager = (managerId: number) => authAxios.delete(BASE_URL + `${managerId}/`)
export const resetPassword = (managerId: number) => authAxios.post(BASE_URL + `${managerId}/reset_password/`)

export const detailManager = ({ queryKey }: { queryKey: any }) => {
    const resultId = queryKey[1]
    return authAxios.get(`${BASE_URL}${resultId}/`)
}
