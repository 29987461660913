
import React from "react"
import { Navigate, useLocation } from "react-router";
import { useIsLoggedIn, getRole } from "./data/auth"

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export const RequireRoleAuth = ({ children, roles }: { children: JSX.Element, roles: string[] }) => {
  const isLoggedIn = useIsLoggedIn();
  const role = getRole();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!roles.includes(role)) {
    return <Navigate to="/" replace />;
  }

  return children
}


export default RequireAuth
