//@ts-nocheck
import Form from "react-bootstrap/esm/Form";
import { useState, useEffect } from "react";
import Page from "../Page";
import Button from "react-bootstrap/esm/Button";
import { formToJson } from "../data/api";
import { BaseApiStatus, setApiStatus } from "../utils/apiStatus";
import { addManager, QUERY_KEY as ManagerQueryKey } from "../data/manager";
import ResponseAlert from "../components/ResponseAlert";
import { useQueryClient } from "react-query";
import CollegeSelect from "../components/CollegeSelect";


const NewManager = () => {
  const [managerType, setManagerType] = useState("Staff");
  const [college, setCollege] = useState(null);
  const [apiResponse, setApiResponse] = useState(BaseApiStatus);

  const queryClient = useQueryClient();

  const handleAddManager = (e) => {
    e.preventDefault();

    const data = formToJson(e);
    const formDataToSubmit = {
      ...data,
      phone_number: "+91"+data.username,
      username: "+91"+data.username,
      college
    };

    setApiStatus(addManager, formDataToSubmit, setApiResponse);
  };

  useEffect(() => {
    queryClient.invalidateQueries(ManagerQueryKey);
  }, [apiResponse, queryClient]);

  return (
    <Page title="Add Manager">
      <Form name="result-form" onSubmit={handleAddManager}>
        <Form.Group key="user_type">
          <Form.Label>Manager Type</Form.Label>
          <Form.Select
            name="type"
            onChange={(e) => setManagerType(e.target.value)}
            defaultValue={managerType}
          >
            <option value="Staff">Stage Manager</option>
            {/* <option value="Manager">College Manager</option> */}
          </Form.Select>
        </Form.Group>
        {managerType === "Manager" && (
          <Form.Group key="college" className="mt-2">
            <CollegeSelect
              onChange={(e) => {
                setCollege(e.length ? e[0].id : null);
              }}
            />
          </Form.Group>
        )}
        <Form.Group key="name" className="mt-2">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" required />
        </Form.Group>
        <Form.Group key="username">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="number" name="username" required />
        </Form.Group>

        <div className="d-grid mt-2 mb-2">
          <Button type="submit">Submit</Button>
        </div>
        <ResponseAlert
          status={apiResponse}
          successHeader="User added successfully"
          successBody={`Username: ${apiResponse?.success?.response?.username}. Password: ${apiResponse?.success?.response?.password}`}
          errorBody={
            apiResponse?.error?.status === 400 
            ? Object.values(apiResponse?.error?.response).map(i => i[0]).join("<br />")
            : "Some error occurred"                            
        }
        />
      </Form>
    </Page>
  );
};

export default NewManager;
