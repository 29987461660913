import { Button, Modal } from "react-bootstrap";
import { TCollege } from "../data/college";

interface IResetCollegePasswordProps {
  showResetConfirmModal: boolean;
  collegeToReset: TCollege;
  handleResetCanceled: () => void;
  handleResetPassword: () => void;
}

const ResetCollegePassword = (props: IResetCollegePasswordProps) => {
  return (
    <Modal
      show={props.showResetConfirmModal}
      onHide={props.handleResetCanceled}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm reset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Confirm you want to reset the password for <br />
          <strong>{props.collegeToReset?.name}</strong>?<br />
          The college will be not be able to login with the old password.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleResetCanceled}>
          No, Cancel.
        </Button>
        <Button variant="danger" onClick={props.handleResetPassword}>
          Yes, Reset password.
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetCollegePassword;
