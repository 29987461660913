//@ts-nocheck
import React from "react";
import { Link, useResolvedPath, useMatch, LinkProps } from "react-router-dom";
import classnames from "classnames";
import { logout, useIsAdmin, useIsResultUser } from "./data/auth";
import { FestDetails } from "./utils/static";
import { dateStrForFestival } from "./utils/date";

function CustomLink({ children, to, className, ...props }: LinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      {...props}
      className={classnames(className, { active: match ? true : false })}
    >
      {children}
    </Link>
  );
}

const Menu: React.FC = () => {
  const isAdmin = useIsAdmin();
  const isResultUser = useIsResultUser();
  return (
    <div className="d-flex flex-column vh-100 py-4 border-end border-double border-secondary">
      <div className="px-4 py-2 border-bottom mb-2">
        <div className="d-flex align-items-center">
          <div style={{ width: "75px" }}>
            <img
              src={FestDetails.logo}
              className="img-fluid shadow"
              style={{ borderRadius: "40px" }}
              alt="logo"
            />
          </div>
          <div className="ms-2">
            <div className="display-6">
              <small>{FestDetails.name}</small>
            </div>
            <strong>{dateStrForFestival(FestDetails)}</strong>
            <br />
            {FestDetails.venue}
          </div>
        </div>
      </div>
      <div className="list-group list-group-flush">
        <CustomLink
          to="/app/"
          className="list-group-item list-group-item-action"
        >
          <div className="fw-bold">Home</div>
          <small>Home page</small>
        </CustomLink>
        {!isAdmin && !isResultUser && (
          <>
            <CustomLink
              to="/app/students"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Students</div>
              <small>Add, edit and delete the details of all students. </small>
            </CustomLink>
            <CustomLink
              to="/app/teams"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Teams</div>
              <small>
                Add, edit and delete the teams participating in events.{" "}
              </small>
            </CustomLink>
            <CustomLink
              to="/app/confirmation"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Confirmation</div>
              <small>
                Confirm the participation after all students and teams have been
                entered.
              </small>
            </CustomLink>
            <CustomLink
              to="/app/print"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Print</div>
              <small>Print, attest and email the final list.</small>
            </CustomLink>
          </>
        )}
        {isResultUser && (
          <>
            <CustomLink
              to="/app/results"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Results</div>
              <small>View, Update results.</small>
            </CustomLink>
            <CustomLink
              to="/app/colleges"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Colleges</div>
              <small>View colleges.</small>
            </CustomLink>
            <CustomLink
              to="/app/events"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Events</div>
              <small>View events, get reports.</small>
            </CustomLink>
          </>
        )}
        {isAdmin && (
          <>
            <CustomLink
              to="/app/students"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Students</div>
              <small>Add, edit and delete the details of all students. </small>
            </CustomLink>
            <CustomLink
              to="/app/teams"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Teams</div>
              <small>
                Add, edit and delete the teams participating in events.{" "}
              </small>
            </CustomLink>
            <CustomLink
              to="/app/schedule"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Schedule</div>
              <small>View, update schedule and venues.</small>
            </CustomLink>
            <CustomLink
              to="/app/colleges"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Colleges</div>
              <small>View colleges, reset confirmation.</small>
            </CustomLink>
            <CustomLink
              to="/app/managers"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Managers</div>
              <small>Add or delete college and stage managers.</small>
            </CustomLink>
            <CustomLink
              to="/app/events"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Events</div>
              <small>View events, get reports.</small>
            </CustomLink>
            <CustomLink
              to="/app/results"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Results</div>
              <small>View results.</small>
            </CustomLink>

            <CustomLink
              to="/app/reports"
              className="list-group-item list-group-item-action"
            >
              <div className="fw-bold">Reports</div>
              <small>Get Reports for college and event.</small>
            </CustomLink>
          </>
        )}
      </div>

      <div className="flex-grow-1">&nbsp;</div>
      <CustomLink
        to="/app/settings"
        className="list-group-item list-group-item-action"
      >
        <div className="fw-bold">Settings</div>
        <small>Change password.</small>
      </CustomLink>
      <div className="px-4 py-2 btn btn-link" onClick={() => logout()}>
        Logout
      </div>
    </div>
  );
};

export default Menu;
