//@ts-nocheck
import { useQuery } from "react-query"
import { reports } from "../data/team"
import { useIsAdmin } from "../data/auth"
import groupBy from "lodash/groupBy"
import { useNavigate } from "react-router"
import { useEffect } from "react"
import Table from "react-bootstrap/esm/Table"
import Button from "react-bootstrap/esm/Button"

const EventReportPrint = () => {
    const { data: reportReponse } = useQuery("reports", reports, { staleTime: Infinity })
    const isAdmin = useIsAdmin()
    const { event_wise_report = [] } = reportReponse?.data || {}
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAdmin) {
            navigate("/app")
        }
    }, [isAdmin])

    return (
        <div>
            <Button size="sm" variant="primary" onClick={() => window.print()}>Print</Button>
            <h3>Event wise Report</h3>
            <Table striped hover bordered>
                <thead>
                    <th>#</th>
                    <th>Category</th>
                    <th>Event Name</th>
                    <th>No. of Teams</th>
                </thead>
                <tbody>
                    {
                        event_wise_report.map((row, i) => {
                            return (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{row.event__category__name}</td>
                                    <td>{row.event__name}</td>
                                    <td>{row.team_count}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>

    )
}

export default EventReportPrint
