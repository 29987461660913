//@ts-nocheck
import { authAxios } from "./api";

export const QUERY_KEY = "students";
const BASE_URL = "app/students/";

export const listStudents = ({ queryKey }) => {
  const params = queryKey[1];
  const queryParams = Object.keys(params)
    .map((p) => `${p}=${params[p]}`)
    .join("&");
  const url = BASE_URL + (queryParams ? `?${queryParams}` : "");
  return authAxios.get(url);
};

export const listStudentsForPrintout = ({ queryKey }) => {
  const params = queryKey[1];
  const queryParams = Object.keys(params)
    .map((p) => `${p}=${params[p]}`)
    .join("&");
  const url = BASE_URL + 'for_printout/' + (queryParams ? `?${queryParams}` : "");
  return authAxios.get(url);
};

export const addStudent = (data: any) => authAxios.post(BASE_URL, data);

export const updateStudent = ({ id, name, phone_number, date_of_birth }) =>
  authAxios.patch(`${BASE_URL}${id}/`, { name, phone_number, date_of_birth });

export const deleteStudent = (userId: number) =>
  authAxios.delete(BASE_URL + `${userId}/`);
export const generateParticipantCardForStudent = ({
  studentId,
}: {
  studentId: number;
}) => authAxios.post(BASE_URL + `${studentId}/generate_participant_card/`);
