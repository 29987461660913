//@ts-nocheck
import Alert from "react-bootstrap/esm/Alert"

type TSuccess = {
    message: string,
    response: any
}

type TError = {
    message: string,
    status: number,
    response: any
}

type TStatus ={
    showAlert: boolean,
    loading: boolean,
    success?: TSuccess,
    error?: TError
}

interface IResponseAlert {
    status: TStatus
    successHeader?: string
    successBody?: string | React.ReactNode
    errorHeader?: string
    errorBody?: string | React.ReactNode
    onClose: () => void
}

const ResponseAlert:React.FC<IResponseAlert> = props => {
    const isSuccess  = !!(props.status.success)
    const variant = isSuccess ? "success": "danger"
    return (
        <Alert variant={variant} show={props.status.showAlert} onClose={() => props.onClose ? props.onClose(): null}>
            <Alert.Heading>{isSuccess ? props.successHeader: props.errorHeader}</Alert.Heading>
            <div>{isSuccess ? props.successBody: props.errorBody} </div>
        </Alert>
    )
}

export default ResponseAlert
