//@ts-nocheck
import Form from "react-bootstrap/esm/Form";
import { useState, useEffect } from "react";
import Page from "../Page";
import Button from "react-bootstrap/esm/Button";
import { formToJson } from "../data/api";
import { BaseApiStatus, setApiStatus } from "../utils/apiStatus";
import ResponseAlert from "../components/ResponseAlert";
import { useQueryClient, useQuery } from "react-query";
import { addCollege, QUERY_KEY as CollegeQueryKey, getCollege, updateCollege } from "../data/college";
import { useParams } from "react-router-dom";


const CollegeForm = () => {
  const [apiResponse, setApiResponse] = useState(BaseApiStatus);
  const queryClient = useQueryClient();
  const { id: collegeId } = useParams();
  
  const isEditForm = !!collegeId;
  const {data: collegeData} = useQuery([CollegeQueryKey, collegeId], getCollege, {enabled: isEditForm});
  const college = collegeData?.data ?? {};

  const handleSaveCollege = (e) => {
    e.preventDefault();

    const data = formToJson(e);
    if (isEditForm) {
      setApiStatus(updateCollege, { collegeId, data }, setApiResponse);
    } else {
      setApiStatus(addCollege, data, setApiResponse);
    }
  };

  const successBody = () => {
    if (isEditForm) {
      return "Updated successfully";
    } else {
      return `Added successfully. Username: ${apiResponse?.success?.response?.username} Password: ${apiResponse?.success?.response?.password}`;
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries(CollegeQueryKey);
  }, [apiResponse, queryClient]);

  return (
    <Page title={isEditForm ? "Edit College": "Add College"}>
      <Form name="result-form" onSubmit={handleSaveCollege}>
        <Form.Group key="name" className="mt-2">
          <Form.Label>Name</Form.Label>
          <Form.Control name="name" required defaultValue={college?.name} />
        </Form.Group>
        <Form.Group key="phone_number">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control type="number" name="phone_number" required defaultValue={college?.user_phone_number} />
          <Form.Text>Phone number of the Fine-Arts Advisor / Manager</Form.Text>
        </Form.Group>
        <Form.Group key="max_teams_per_college_per_event">
          <Form.Label>Teams per event</Form.Label>
          <Form.Control
            type="number"
            name="max_teams_per_college_per_event"
            defaultValue={college?.max_teams_per_college_per_event}
            required
          />
          <Form.Text>
            For colleges with more students, the number of teams per event may
            be more than one.
          </Form.Text>
        </Form.Group>

        <div className="d-grid mt-2">
          <Button type="submit">Save</Button>
        </div>
        <div className="mt-2">
        <ResponseAlert
          status={apiResponse}
          successHeader="Success"
          successBody={successBody()}
          errorBody={
            apiResponse?.error?.status === 400
              ? Object.values(apiResponse?.error?.response)
                .map((i) => i[0])
                .join("<br />")
              : "Some error occurred"
          }
        />
        </div>
      </Form>
    </Page>
  );
};

export default CollegeForm;
