//@ts-nocheck
import { useParams } from "react-router";
import { useQuery } from "react-query";
import {
  QUERY_KEY as ResultQueryKey,
  detailResult,
  generateCertificate,
} from "../data/result";
import Page from "../Page";
import Spinner from "react-bootstrap/esm/Spinner";
import Table from "react-bootstrap/esm/Table";
import chunk from "lodash/chunk";
import { useReactToPrint } from "react-to-print";
import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { FestDetails } from "../utils/static";
import { setApiStatus } from "../utils/apiStatus";

const ResultDetail = () => {
  const { id } = useParams();
  const { data: resultDetail, ...resultDetailMeta } = useQuery(
    [`${ResultQueryKey}/${id}`, id],
    detailResult,
    { staleTime: Infinity, enabled: !!id }
  );

  const [generateCertificateStatus, setGenerateCertificateStatus] = useState({
    loading: false,
    showAlert: false,
  });

  const handleOnGenerateCertificate = () =>
    setApiStatus(
      () => generateCertificate(id),
      {},
      setGenerateCertificateStatus
    );

  useEffect(() => {
    if (
      !generateCertificateStatus.loading &&
      generateCertificateStatus.success
    ) {
      window.open(
        generateCertificateStatus.success.response.certificate_url,
        "_blank"
      );
    }
  }, [generateCertificateStatus]);

  const StudentCell = ({ student }: { student: any }) => (
    <td>
      <div className="d-flex align-items-center">
        <img
          src={student.photo}
          alt={student.name}
          width="50px"
          className="img-fluid me-2"
        ></img>
        <div>
          <strong>{student.name}</strong>
          <br />
          <small>{student.class_name}</small>
        </div>
      </div>
    </td>
  );

  const componentToPrint = useRef();
  const handleOnPrint = useReactToPrint({
    //@ts-ignore
    content: () => componentToPrint.current,
    documentTitle: `${FestDetails.name} - Result - ${resultDetail?.data.event}`,
    pageStyle: "@page { size: landscape; };",
  });

  if (resultDetailMeta.isLoading) return <></>;

  return (
    <Page title="Results">
      <div className="my-2">
        <Button onClick={handleOnPrint}>Print</Button>{" "}
        <Button
          onClick={handleOnGenerateCertificate}
          disabled={generateCertificateStatus.loading}
        >
          {generateCertificateStatus.loading && (
            <Spinner animation="border" size="sm" />
          )}
          Generate Certificate
        </Button>
      </div>
      <div ref={componentToPrint}>
        <h4>Event: {resultDetail.data.event.name}</h4>
        <Table hover striped bordered>
          <thead>
            <tr>
              <th>Position</th>
              <th>College</th>
              <th>Grade</th>
              <th>Points</th>
              <th
                colSpan={Math.min(
                  resultDetail?.data.items[0].team.students.length,
                  2
                )}
              >
                Participants
              </th>
            </tr>
          </thead>
          <tbody>
            {resultDetail?.data.items.map((row, idx) => {
              const studentGroups = chunk(row.team.students, 2);
              return (
                <>
                  <tr>
                    <td valign="middle" rowSpan={studentGroups.length}>
                      {row.position}
                    </td>
                    <td valign="middle" rowSpan={studentGroups.length}>
                      {row.team.college.name}
                    </td>
                    <td valign="middle" rowSpan={studentGroups.length}>
                      {row.grade}
                    </td>
                    <td valign="middle" rowSpan={studentGroups.length}>
                      {row.points}
                    </td>
                    {studentGroups[0].map((student) => (
                      <StudentCell student={student} />
                    ))}
                  </tr>
                  {studentGroups.slice(1).map((chunk) => (
                    <tr>
                      {chunk.map((student) => (
                        <StudentCell student={student} />
                      ))}
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Page>
  );
};

export default ResultDetail;
