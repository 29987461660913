//@ts-nocheck
import React from "react";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Menu from "./Menu";

interface IPage {
  title: String;
}

const Page: React.FC<IPage> = (props) => {
  return (
    <div className="vh-100 vw-100">
      <Container fluid>
        <Row>
          <Col md={3} className="p-0">
            <Menu />
          </Col>
          <Col md={9} className="overflow-scroll vh-100">
            <h4 className="px-4 pt-4 pb-2">{props.title}</h4>
            <div className="p-4">{props.children}</div>
            <div className="text-end fixed-bottom mb-2 me-4">
              Powered by{" "}
              <a href="https://tiqr.events" target={"_blank"}>
                TiQR Events
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page;
