//@ts-nocheck
import Form from "react-bootstrap/Form"
import { useQuery } from "react-query"
import { listTeam, QUERY_KEY as TeamQueryKey } from "../data/team"
import Spinner from "react-bootstrap/esm/Spinner"
import { Typeahead } from "react-bootstrap-typeahead"

const TeamSelect: React.FC = ({name="team", event, teamSize, disabled, onChange, showLabel=true}) => {
    const { data: teamsResponse, ...teamMeta } = useQuery([TeamQueryKey, {event}], listTeam, { staleTime: Infinity, enabled: !!event })
    if (teamMeta.isLoading || teamMeta.isFetching)
        return <Spinner animation="border" />

    const buildLabel = (team: any) => {
        if (teamSize === 1) {
            return {id: team.id, label: `${team.primary_student.name} (${team.college.name})`}
        } else {
            return{ id: team.id, label: `${team.college.name} (${team.primary_student.name} and team)` }
        }
    }
    const options = (teamsResponse?.data.results || []).map(team => buildLabel(team))
    

    
    return (
        <Form.Group>
            {showLabel && <Form.Label>Team</Form.Label>}
            <Typeahead
                id="team-select"
                name={name}
                options={options}
                onChange={onChange}
                placeholder="Search the Team"
                disabled={disabled}
                clearButton
            />
        </Form.Group>
    )
}

export default TeamSelect
