//@ts-nocheck
type TSuccess = {
  message: string;
  response: any;
};
type TError = {
  message: string;
  status: number;
  response: any;
};
export type TApiStatusResponse = {
  showAlert: boolean;
  loading: boolean;
  success: undefined | TSuccess;
  error: undefined | TError;
};

export const BaseApiStatus: TApiStatusResponse = {
  showAlert: false,
  loading: false,
};

export const LoadingStatus: TApiStatusResponse = {
  showAlert: false,
  loading: true,
  success: undefined,
  error: undefined,
};

export const setApiStatus = (
  apiCall: any,
  data: any,
  cb: (response: TApiStatusResponse) => void
) => {
  cb({
    loading: true,
  })
  apiCall(data)
    .then((response) => {
      if ([200, 201, 204].includes(response.status)) {
        cb({
          showAlert: true,
          loading: false,
          success: {
            message: "Success",
            response: response.data,
          },
          error: undefined,
        });
      } else {
        cb({
          showAlert: true,
          loading: false,
          success: undefined,
          error: {
            message: response.data.error?.message || "Error",
            status: response.status,
            response: response.data.error?.details,
          },
        });
      }
    })
    .catch((error) => {
      console.log(error)
        cb({
        showAlert: true,
        loading: false,
        success: undefined,
        error: {
          message: "Some error occurred",
          status: 0,
          response: error,
        },
      });
    });
};
