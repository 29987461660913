//@ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import Login from "./auth/Login";
import Dashboard from "./dashboard";
import StudentIndex from "./student";
import { QueryClientProvider, QueryClient } from "react-query";
import NewStudent from "./student/NewStudent";
import TeamIndex from "./team";
import NewTeam from "./team/NewTeam";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ConfirmAllSubmissions from "./team/ConfirmAllSubmissions";
import PrintConfirmation from "./team/PrintConfirmation";
import PrintOutPage from "./team/PrintOut";
import { ReactQueryDevtools } from "react-query/devtools";
import EventsPreLoad from "./team/EventsPreLoad";
import ReportsIndex from "./reports";
import CollegeIndex from "./college";
import CollegeForm from "./college/CollegeForm";
import CollegeReportPrint from "./reports/CollegeReportPrint";
import EventReportPrint from "./reports/EventReportPrint";
import EventsIndex from "./events";
import { RequireRoleAuth } from "./RequireAuth";
import EventDetail from "./events/EventDetail";
import ScheduleIndex from "./schedule";
import ResultIndex from "./result";
import NewResult from "./result/NewResult";
import ResultDetail from "./result/ResultDetail";
import Settings from "./settings";
import "./index.css";
import ManagerIndex from "./managers";
import NewManager from "./managers/NewManager";
import CollegesPreLoad from "./team/CollegesPreLoad";
import { FestDetails } from "./utils/static";
import Website from "./website";
import CategoriesPreLoad from "./components/CategoriesPreLoad";

const queryClient = new QueryClient();

document.title = `${FestDetails.name} - ${FestDetails.year}`;
ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/"
          element={
            <Website />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/app"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/app/students"
          element={
            <RequireAuth>
              <StudentIndex />
            </RequireAuth>
          }
        />
        <Route
          path="/app/students/new"
          element={
            <RequireAuth>
              <NewStudent />
            </RequireAuth>
          }
        />
        <Route
          path="/app/teams"
          element={
            <RequireAuth>
              <TeamIndex />
            </RequireAuth>
          }
        />
        <Route
          path="/app/teams/new"
          element={
            <RequireAuth>
              <NewTeam />
            </RequireAuth>
          }
        />
        <Route
          path="/app/confirmation"
          element={
            <RequireAuth>
              <ConfirmAllSubmissions />
            </RequireAuth>
          }
        />
        <Route
          path="/app/print"
          element={
            <RequireAuth>
              <PrintConfirmation />
            </RequireAuth>
          }
        />
        <Route
          path="/app/printout"
          element={
            <RequireAuth>
              <PrintOutPage />
            </RequireAuth>
          }
        />
        <Route
          path="/app/reports"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <ReportsIndex />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/reports/printcollege"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <CollegeReportPrint />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/reports/printevent"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <EventReportPrint />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/colleges/add"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <CollegeForm />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/colleges/edit/:id"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <CollegeForm />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/colleges"
          element={
            <RequireRoleAuth roles={["admin", "result"]}>
              <CollegeIndex />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/events"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <EventsIndex />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/events/:id"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <EventDetail />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/schedule"
          element={
            <RequireRoleAuth roles={["admin"]}>
              <ScheduleIndex />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/results"
          element={
            <RequireRoleAuth roles={["admin", "result"]}>
              <ResultIndex />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/results/add"
          element={
            <RequireRoleAuth roles={["result"]}>
              <NewResult />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/results/:id"
          element={
            <RequireRoleAuth roles={["admin", "result"]}>
              <ResultDetail />
            </RequireRoleAuth>
          }
        />
        <Route
          path="/app/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/app/managers"
          element={
            <RequireAuth roles={["admin"]}>
              <ManagerIndex />
            </RequireAuth>
          }
        />
        <Route
          path="/app/managers/add"
          element={
            <RequireAuth roles={["admin"]}>
              <NewManager />
            </RequireAuth>
          }
        />
      </Routes>
      <EventsPreLoad />
      <CollegesPreLoad />
      <CategoriesPreLoad />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
