//@ts-nocheck
import Form from "react-bootstrap/Form"
import { useQuery } from "react-query"
import Spinner from "react-bootstrap/esm/Spinner"
import { Typeahead } from "react-bootstrap-typeahead"
import { allColleges, QUERY_KEY as CollegeQueryKey } from "../data/college"

const CollegeSelect: React.FC = props => {
    const { data: collegesList, ...collegesListMeta } = useQuery(CollegeQueryKey+"-all", allColleges, { staleTime: Infinity })
    if (collegesListMeta.isLoading || collegesListMeta.isFetching)
        return <Spinner animation="border" />

    const options = (collegesList?.data.results || []).map(e => ({ id: e.id, label: e.name  }))
    const defaultSelected = props.value ? options.filter(o => o.id === props.value) : undefined
    if (props.value && !defaultSelected) return <Spinner animation="border" />

    
    return (
        <Form.Group>
            <Form.Label>College</Form.Label>
            <Typeahead
                id="college-select"
                name="college"
                options={options}
                onChange={props.onChange}
                placeholder="Search the college"
                disabled={props.disabled}
                selected={defaultSelected}
                clearButton
            />
        </Form.Group>
    )
}

export default CollegeSelect
