//@ts-nocheck
import Page from "../Page"
import Form from "react-bootstrap/esm/Form"
import Row from "react-bootstrap/esm/Row"
import Alert from "react-bootstrap/esm/Alert"
import Button from "react-bootstrap/esm/Button"
import Col from "react-bootstrap/esm/Col"
import { useState } from "react"
import { confirm, CONFIRMATION_QUERY_KEY, useHasConfirmed } from "../data/team"
import { formToJson } from "../data/api"
import { setApiStatus } from "../utils/apiStatus"
import ResponseAlert from "../components/ResponseAlert"
import { useQueryClient } from "react-query"


const ConfirmAllSubmissions: React.FC = () => {
    const queryClient = useQueryClient()
    const [boxChecked, setBoxChecked] = useState(false)
    const [submissionStatus, setSubmissionStatus] = useState({ showAlert: false, loading: false, success: undefined, error: undefined })
    
    const handleConfirmationSubmit = (e) => {
        e.preventDefault()

        const dataJson = formToJson(e)
        setApiStatus(confirm, dataJson, response => {
            setSubmissionStatus(response)
            queryClient.invalidateQueries(CONFIRMATION_QUERY_KEY)
        })
    }

    const confirmationStatus = useHasConfirmed()

    const alreadySubmitted = (
        confirmationStatus.hasConfirmed || !!submissionStatus.success
    )

    return (
        <Page title="Confirm Submissions">
            <Col xs={12} md={9}>
                <Row>
                    <Alert variant="warning" show={!alreadySubmitted}>
                        <Alert.Heading>Are you sure to confirm all submissions?</Alert.Heading>
                        <p>
                            Once you confirm and submit, <strong>NO CHANGES</strong> will be allowed to any students and teams you registered. <br />
                    Please be double-sure before confirming the submissions.
                </p>
                    </Alert>
                </Row>
                <Form onSubmit={handleConfirmationSubmit} >
                    <fieldset disabled={alreadySubmitted}>
                        <Form.Group>
                            <Form.Label>Enter Password to Confirm</Form.Label>
                            <Form.Control type="password" name="password" placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Check
                                name="confirm_checkbox"
                                type="checkbox"
                                label="I confirm that all students and teams have been entered and verified. No more changes are required for my college."
                                onChange={(e) => setBoxChecked(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Button type="submit" disabled={!boxChecked} >Confirm and Complete Registration</Button>
                        </Form.Group>
                    </fieldset>
                </Form>
                <Row className="mt-2">
                    <ResponseAlert
                        status={alreadySubmitted ? { showAlert: true, loading: false, success: true } : submissionStatus}
                        successHeader={"Confirmation successful"}
                        // successBody={(<LastStep />)}
                        errorHeader={"Confirmation failed"}
                        errorBody={(
                            <p>
                                {
                                    submissionStatus.error?.status === 400 ? (
                                        <p>Reason: {submissionStatus.error?.response}</p>
                                    ) : (
                                            <p>{submissionStatus.error?.message}</p>
                                        )
                                }
                            </p>
                        )}
                    />
                </Row>
            </Col>
        </Page>
    )
}

export default ConfirmAllSubmissions
