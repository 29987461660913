//@ts-nocheck
import Page from "../Page";
import { useQuery } from "react-query";
import { useState } from "react";
import Form from "react-bootstrap/esm/Form";
import Spinner from "react-bootstrap/esm/Spinner";
import Table from "react-bootstrap/esm/Table";
import Pagination from "../components/Pagination";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { formToJson } from "../data/api";
import {
  listManagers,
  QUERY_KEY as ManagerQueryKey,
  removeManager,
  resetPassword,
} from "../data/manager";
import { Modal } from "react-bootstrap";
import { BaseApiStatus, setApiStatus } from "../utils/apiStatus";
import ResponseAlert from "../components/ResponseAlert";
import keyBy from "lodash/keyBy";
import { useQueryClient } from "react-query";

const ManagerIndex = () => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [managerToDelete, setManagerToDelete] = useState(undefined);
  const [managerName, setManagerName] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteApiResponse, setDeleteApiResponse] = useState(BaseApiStatus);

  const [managerToResetPassword, setManagerToResetPassword] =
    useState(undefined);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetApiResponse, setResetApiResponse] = useState(BaseApiStatus);

  const [operation, setOperation] = useState(undefined);
  const queryClient = useQueryClient();

  const getParams = () => {
    const params = { page, page_size: 20 };
    if (searchQuery.length > 2) {
      params.search = searchQuery;
    }
    return params;
  };
  const { data: managersResponse, ...managersMeta } = useQuery(
    [ManagerQueryKey, getParams()],
    listManagers
  );
  const managersMap = keyBy(managersResponse?.data.results, u => parseInt( u.id));
  

  const handleSearch = (e) => {
    e.preventDefault();
    const data = formToJson(e);
    setSearchQuery(data.search);
  };

  const handleClickOnDelete = (managerId) => () => {
    setOperation("delete");
    setManagerToDelete(managerId.toString());
    setShowDeleteModal(true);
  };

  const handleClickOnReset = (managerId) => () => {
    setOperation("reset_password");
    setManagerToResetPassword(managerId.toString());
    setShowResetModal(true);
  };

  const handleConfirmDelete = () => {
    setApiStatus(removeManager, managerToDelete, (response) => {
      setDeleteApiResponse(response);
      if (response.success) {
        setManagerName(managersMap[managerToDelete].username);
        queryClient.invalidateQueries([ManagerQueryKey, getParams()]);
      }
      setShowDeleteModal(false);
      setManagerToDelete(undefined);
    });
  };

  const handleConfirmReset = () => {
    setApiStatus(resetPassword, managerToResetPassword, (response) => {
      setResetApiResponse(response);
      if (response.success) {
        setManagerName(managersMap[managerToResetPassword].username);
        queryClient.invalidateQueries([ManagerQueryKey, getParams()]);
      }
      setShowResetModal(false);
      setManagerToResetPassword(undefined);
    });
  };

  const handleDeleteCanceled = () => {
    setManagerToDelete(undefined);
    setShowDeleteModal(false);
    setOperation(undefined);
  };

  const handleResetCanceled = () => {
    setManagerToResetPassword(undefined);
    setShowResetModal(false);
    setOperation(undefined);
  };

  return (
    <Page title="Managers">
      <Link to="./add" className="btn btn-primary mb-2">
        Add Manager
      </Link>
      <Modal show={showDeleteModal} onHide={handleDeleteCanceled}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm you want to delete{" "}
          <strong>{managersMap[managerToDelete]?.username}</strong> (
          {managersMap[managerToDelete]?.college?.name || "Stage manager"})?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCanceled}>
            No, Cancel.
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes, Delete.
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showResetModal} onHide={handleResetCanceled}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm reset password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm you want to reset password{" "}
          <strong>{managersMap[managerToResetPassword]?.username}</strong> (
          {managersMap[managerToResetPassword]?.college?.name ||
            "Stage manager"}
          )?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleResetCanceled}>
            No, Cancel.
          </Button>
          <Button variant="danger" onClick={handleConfirmReset}>
            Yes, Reset Password.
          </Button>
        </Modal.Footer>
      </Modal>
      {managerName && operation === "delete" && (
        <ResponseAlert
          status={deleteApiResponse}
          successBody={`Deleted ${managerName} successfully`}
          errorBody={
            deleteApiResponse?.error?.status === 400
              ? Object.values(deleteApiResponse?.error?.response)
                  .map((i) => i[0])
                  .join("<br />")
              : "Some error occurred"
          }
        />
      )}
      {managerName && operation === "reset_password" && (
        <ResponseAlert
          status={resetApiResponse}
          successBody={`Password reset successfully for user ${managerName}. New password is ${resetApiResponse?.success?.response?.password}`}
          errorBody={
            resetApiResponse?.error?.status === 400
              ? Object.values(resetApiResponse?.error?.response)
                  .map((i) => i[0])
                  .join("<br />")
              : "Some error occurred"
          }
        />
      )}
      <Form onSubmit={handleSearch}>
        <div className="d-flex mb-3">
          <div className="me-2 flex-grow-1">
            <Form.Group>
              <Form.Control name="search" placeholder="Search for user" />
              <Form.Text>
                Search using name, phone number of college name
              </Form.Text>
            </Form.Group>
          </div>
          <div>
            <Button type="submit">Search</Button>
          </div>
        </div>
      </Form>
      {managersMeta.isLoading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Table hover striped bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>College</th>
                <th>Phone</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {managersResponse?.data.results.map((row, idx) => (
                <>
                  <tr>
                    <td valign="middle">{idx + 1}</td>
                    <td valign="middle">{row.name}</td>
                    <td valign="middle">{row.college?.name}</td>
                    <td valign="middle">{row.phone_number}</td>
                    <td valign="middle">{row.type}</td>
                    <td>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={handleClickOnDelete(row.id)}
                      >
                        Delete
                      </Button>
                      <Button
                        size="sm"
                        variant="info"
                        onClick={handleClickOnReset(row.id)}
                        className="ms-2"
                      >
                        Reset Password
                      </Button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={page}
            itemCount={managersResponse?.data?.count || 0}
            setPage={setPage}
            itemsPerPage={20}
          />
        </>
      )}
    </Page>
  );
};

export default ManagerIndex;
