import { Button, Modal } from "react-bootstrap";
import { TCollege } from "../data/college";

interface IResetConfirmationProps {
    showResetConfirmModal: boolean
    collegeToReset: TCollege
    handleResetCanceled: () => void
    handleResetCollege: () => void
}

const ResetConfirmation = (props: IResetConfirmationProps) => {
    return <Modal show={props.showResetConfirmModal} onHide={props.handleResetCanceled}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Confirm you want to reset{" "}
            <strong>{props.collegeToReset?.name}</strong>?<br />
            The college will be able to make changes to the students and teams
            which they have already added.
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.handleResetCanceled}>
                No, Cancel.
            </Button>
            <Button variant="danger" onClick={props.handleResetCollege}>
                Yes, Reset confirmation.
            </Button>
        </Modal.Footer>
    </Modal>;
}


export default ResetConfirmation;
