//@ts-nocheck
import Page from "../Page"
import { useQuery } from "react-query"
import { QUERY_KEY as EventQueryKey, listEvents } from "../data/event"
import Table from "react-bootstrap/esm/Table"
import Spinner from "react-bootstrap/esm/Spinner"
import orderBy from "lodash/orderBy"
import { Link } from "react-router-dom"
import Form from "react-bootstrap/esm/Form"
import { useState } from "react"
import debounce from "lodash/debounce"

const EventsIndex = () => {
    const [searchQuery, setSearchQuery] = useState("")
    const { data: eventsResponse, ...eventsResponseMeta } = useQuery(EventQueryKey, listEvents)

    const eventsList = eventsResponse?.data.results || []
    const events = orderBy(
        searchQuery.length > 2 ? eventsList.filter(e => e.name.toLowerCase().includes(searchQuery)) : eventsList
        , ["category.name", "name"])

    const debouncedSearch = debounce((e) => setSearchQuery(e.target.value), [200])

    return (
        <Page title="Events">
            {eventsResponseMeta.isLoading && <Spinner animation="border" />}
            {!eventsResponseMeta.isLoading && (
                <>
                    <div className="mb-2">
                        <Form.Control placeholder="Search for Event" onChange={debouncedSearch} />
                    </div>
                    <Table hover striped bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Event</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map(
                                (event, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{event.category.name}</td>
                                        <td>{event.name}</td>
                                        <td>
                                            <Link to={`./${event.id}`} className="btn btn-primary btn-sm">Details</Link>
                                            <Link to={`/app/schedule?event=${event.name}`} className="btn btn-primary btn-sm ms-2">Schedule</Link>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </>
            )}
        </Page>
    )
}

export default EventsIndex
