//@ts-nocheck
import { useState } from "react"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import { useMutation } from "react-query";
import { postLogin, login } from "../data/auth";
import { useNavigate, useLocation } from "react-router";
import { formToJson } from "../data/api";
import { FestDetails } from "../utils/static";
import { dateStrForFestival } from "../utils/date";


function Login(props) {
    const [showError, setShowError] = useState(true)
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/app";

    const { mutate: tryLogin, ...tryLoginMeta } = useMutation(postLogin, {
        onSuccess: (response) => {
            login(response.data.access)
            navigate(from, { replace: true })
        },
        onError: (response) => {
            setShowError(true)
        }
    })

    const handleSubmit = (event) => {
        event.preventDefault()
        const formJson = formToJson(event)
        tryLogin(formJson)
    }

    return (
        <div className="vh-100 min-vw-100 d-flex justify-content-center align-items-center">
            <Container className="w-75" >
                <Row className="align-items-center">
                    <Col md="6" className="border-end border-secondary">
                        <div className="d-flex align-items-center">
                            <div style={{ width: "100px" }}>
                                <img src={FestDetails.logo} className="img-fluid shadow" style={{ borderRadius: "50px" }} alt="logo" />
                            </div>
                            <div className="ms-2">
                                <div className="display-6">
                                    <small>{FestDetails.name} {FestDetails.year}</small>
                                </div>
                                <strong>{dateStrForFestival(FestDetails)}</strong><br />
                    {FestDetails.venue}
                    </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>User ID</Form.Label>
                                <Form.Control type="text" name="username" placeholder="Ex. KNR_1234" />
                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" placeholder="Password" />
                            </Form.Group>
                            <Toast show={showError && tryLoginMeta.isError} bg="warning" autohide onClose={() => setShowError(false)} className="mt-2">
                                <Toast.Body>{tryLoginMeta.error?.response?.status === 401 ? "Incorrect username and password." : 'Some error occurred.'}</Toast.Body>
                            </Toast>
                            <Form.Group className="mt-2">
                                <Button type="submit">Login</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Login
