//@ts-nocheck
import Page from "../Page"
import Button from "react-bootstrap/esm/Button"
import Alert from "react-bootstrap/esm/Alert"
import { LastStep } from "./LastStep"
import { useHasConfirmed } from "../data/team"
import ResponseAlert from "../components/ResponseAlert"
import { useState, useEffect } from "react"

const PrintConfirmation: React = () => {
    const [responseStatus] = useState({loading: false, showAlert: false})

    const handlePrintClick = () => window.location.href = "./printout"
    const confirmationStatus = useHasConfirmed()
    
    useEffect(() => {
        if(!responseStatus.loading && responseStatus.success) {
            window.open(`${process.env.REACT_APP_API_URL}${responseStatus.success.response.url}`, "_blank")
        }
    }, [responseStatus])
            

    return (
        <Page title="Print Confirmation">
            {
            confirmationStatus.hasConfirmed? 
            (<Alert variant="info">
                <LastStep />
            </Alert>):
            (
                <Alert variant="warning">
                <p>Submissions has not been confirmed. Goto "Confirmation" page and confirm the submissions before taking the prinout.</p>
            </Alert>
            )
        }
            <Button disabled={!confirmationStatus.hasConfirmed} onClick={handlePrintClick}>Print Confirmation</Button>
            <ResponseAlert 
                status={responseStatus} 
                successHeader=""
                successBody="Redirecting to prinout page."
                errorHeader={responseStatus?.error?.status === 400 ? "Error" : "Some error occurred."}
                errorBody={responseStatus?.error?.status === 400 ? responseStatus?.error?.response?.error: ""}
            />
        </Page>
    )
}

export default PrintConfirmation
