//@ts-nocheck
import Page from "../Page";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from "react-bootstrap/esm/Tab";
import { useQuery } from "react-query";
import { reports } from "../data/team";
import groupBy from "lodash/groupBy";
import Table from "react-bootstrap/esm/Table";
import keyBy from "lodash/keyBy";
import { useIsAdmin } from "../data/auth";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/esm/Button";
import { useReactToPrint } from "react-to-print";
import { FestDetails } from "../utils/static";
import { listCategories } from "../data/category";

const ReportsIndex = () => {
  const { data: reportReponse } = useQuery("reports", reports, {
    staleTime: Infinity,
  });
  const isAdmin = useIsAdmin();
  const { college_wise_report = {}, event_wise_report = [] } =
    reportReponse?.data || {};
  const collegeWiseReport = groupBy(college_wise_report, "college__name");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate("/app");
    }
  }, [isAdmin, navigate]);

  const collegeComponentToPrintRef = useRef();
  const handleCollegePrint = useReactToPrint({
    content: () => collegeComponentToPrintRef.current,
    documentTitle: `${FestDetails.name} - College wise report`,
    pageStyle: "@page { size: landscape; };",
  });

  const eventComponentToPrintRef = useRef();
  const handleEventPrint = useReactToPrint({
    content: () => eventComponentToPrintRef.current,
    documentTitle: `${FestDetails.name} - Event wise report`,
    pageStyle: "@page { size: potrait; };",
  });

  const { data: categoryResponse } = useQuery("category", listCategories, {
    staleTime: Infinity,
  });

  return (
    <Page title="Reports">
      <Tabs defaultActiveKey="college" className="mb-3">
        <Tab eventKey="college" title="College wise report">
          <Button onClick={handleCollegePrint}>Print</Button>
          <div ref={collegeComponentToPrintRef}>
            <h3>College wise Report</h3>
            <Table striped hover bordered>
              <thead>
                <tr>
                  <th valign="middle" rowSpan={2}>
                    #
                  </th>
                  <th valign="middle" rowSpan={2}>
                    College Name
                  </th>
                  {(categoryResponse?.data.results ?? []).map((c) => (
                    <th
                      valign="middle"
                      className="th-vertical"
                      width="7%"
                      colSpan={2}
                    >
                      {c.name}
                    </th>
                  ))}
                </tr>
                <tr>
                  {(categoryResponse?.data.results ?? []).map((c) => (
                    <>
                      <th>F</th>
                      <th>M</th>
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.values(collegeWiseReport).map((row, i) => {
                  const perCollegeReport = keyBy(
                    row,
                    "event__rule_category__name"
                  );
                  return (
                    <>
                      <tr>
                        <td valign="middle" rowSpan={2}>
                          {i + 1}
                        </td>
                        <td valign="middle" rowSpan={2}>
                          {row[0].college__name}
                        </td>
                        {(categoryResponse?.data.results ?? []).map((c) => (
                          <td align="center" colSpan={2}>
                            {perCollegeReport[c.name]?.team_count || 0}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {(categoryResponse?.data.results ?? []).map((c) => (
                          <>
                            <td>{perCollegeReport[c.name]?.male || 0}</td>
                            <td>{perCollegeReport[c.name]?.female || 0}</td>
                          </>
                        ))}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="event" title="Event wise report">
          <Button onClick={handleEventPrint}>Print</Button>
          <div ref={eventComponentToPrintRef}>
            <h3>Event wise Report</h3>

            <Table striped hover bordered>
              <thead>
                <th>#</th>
                <th>Category</th>
                <th>Event Name</th>
                <th>No. of Teams</th>
              </thead>
              <tbody>
                {event_wise_report.map((row, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{row.event__category__name}</td>
                      <td>{row.event__name}</td>
                      <td>{row.team_count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Tab>
      </Tabs>
    </Page>
  );
};

export default ReportsIndex;
