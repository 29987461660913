//@ts-nocheck
import BSPagination from "react-bootstrap/esm/Pagination";

interface IPagination {
    currentPage: number
    itemCount: number
    itemsPerPage: number
    setPage: () => void
}

const Pagination: React.FC<IPagination> = props => {
    const numPages = (props.itemCount / props.itemsPerPage) + (props.itemCount % props.itemsPerPage === 0 ? 0 : 1)
    const pageNumers = []
    for (let i = 1; i <= numPages; i++) pageNumers.push(i)

    return (
        <>
            <hr />
            <BSPagination>
                {pageNumers.map(p => <BSPagination.Item onClick={() => props.setPage && props.setPage(p)} active={p === props.currentPage}>{p}</BSPagination.Item>)}
            </BSPagination>
            <p>Showing {(props.currentPage - 1) * props.itemsPerPage + 1} to {Math.min( props.currentPage * props.itemsPerPage, props.itemCount)} out of {props.itemCount}</p>
        </>
    )
}

Pagination.defaultProps = {
    currentPage: 1,
    itemsPerPage: 20
}

export default Pagination
