//@ts-nocheck
import { useQuery } from "react-query"
import { reports } from "../data/team"
import { useIsAdmin } from "../data/auth"
import groupBy from "lodash/groupBy"
import { useNavigate } from "react-router"
import { useEffect, useRef } from "react"
import Table from "react-bootstrap/esm/Table"
import keyBy from "lodash/keyBy"
import Button from "react-bootstrap/esm/Button"
import { useReactToPrint } from 'react-to-print';
import { FestDetails } from "../utils/static"
import { listCategories } from "../data/category"

const CollegeReportPrint = () => {
    const { data: reportReponse } = useQuery("reports", reports, { staleTime: Infinity })
    const isAdmin = useIsAdmin()
    const { college_wise_report = {} } = reportReponse?.data || {}
    const collegeWiseReport = groupBy(college_wise_report, "college__name")
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAdmin) {
            navigate("/app")
        }
    }, [isAdmin])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${FestDetails.name} - College wise report`,
        pageStyle: "@page { size: landscape; }"
    })

    const renderPerCollegePerCategory = (report) => (
        <div className="text-center">
            {report?.team_count || 0}<br />
            <small style={{fontSize: "0.8em"}}>
                {report?.female && `F: ${report?.female || 0},`}
                {report?.male && `M: ${report?.male || 0},`}
                {report?.transgender && `T: ${report?.transgender || 0},`}
            </small>
        </div>
    )

    const { data: categoryResponse } = useQuery("category", listCategories, { staleTime: Infinity })

    return (
        <div>
            <Button size="sm" variant="primary" onClick={handlePrint}>Print</Button>
            <div ref={componentRef} >
                <h3>College wise Report</h3>
                <Table striped hover bordered>
                    <thead>
                        <th>#</th>
                        <th>College Name</th>
                        {
                            (categoryResponse?.data.results ?? []).map(c => (
                                <th>{c.name}</th>
                            ))
                        }
                    </thead>
                    <tbody>
                        {
                            Object.values(collegeWiseReport).map((row, i) => {
                                const perCollegeReport = keyBy(row, "event__rule_category__name")
                                return (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{row[0].college__name}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Off-Stage'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Sahithyolsavam'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Chithrolsavam'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['On-Stage'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Sangeetholsavam'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Nritholsavam'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Drishya-Natakolsavam'])}</td>
                                        <td>{renderPerCollegePerCategory(perCollegeReport['Natakam'])}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>)
}

export default CollegeReportPrint
