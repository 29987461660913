//@ts-nocheck
import { authAxios, noAuthAxios } from "./api"

const BASE_URL = "app/colleges/"
export const QUERY_KEY = "colleges"
export const listColleges = ({ queryKey }) => {
    const [key, params] = queryKey
    const queryParams = Object.keys(params).map(p => `${p}=${params[p]}`).join("&")
    const url = BASE_URL + (queryParams ? `?${queryParams}` : "")
    return authAxios.get(url)
}

export const allColleges = () => noAuthAxios.get(BASE_URL + "?page_size=250")

// export const addCollege = (data: any) => authAxios.post(BASE_URL, data)
// export const editCollege = (collegeId: number, data: any) => authAxios.put(BASE_URL + `${collegeId}/`, data)
// export const deleteCollege = (collegeId: number) => authAxios.delete(BASE_URL + `${collegeId}/`)

export const resetCollege = (collegeId: number) => authAxios.post(BASE_URL + `${collegeId}/reset/`)
export const resetCollegePassword = (collegeId: number) => authAxios.post(BASE_URL + `${collegeId}/reset_password/`)
export const getPrintUrl = (collegeId: number) => authAxios.get(`${BASE_URL}${collegeId}/print_url/`)

export const addCollege = (data: any) => authAxios.post(BASE_URL, data)
export const updateCollege = ({collegeId, data}: {collegeId: number, data: any}) => authAxios.patch(BASE_URL + `${collegeId}/`, data)
export const getCollege = ({queryKey}) => {
    const [key, collegeId] = queryKey
    return authAxios.get(BASE_URL + `${collegeId}/`)
}

export type TCollege = {
    id: number
    name: string
}
